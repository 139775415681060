* {
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@100..900&family=Lato&display=swap');
html,
body,
#root {
  height: 100%;
  overflow: auto;
}

body {
  width: 350px;
  margin: 0;
  font-family: 'Chivo Mono', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.consents {
  font-family: Lato, sans-serif;
  font-size: 14px;
}

.consents div {
  margin-bottom: 5px;
}

.ProductHeader {
  width: 100%;
  background-color: #041e42;
  height: 80px;
  text-align: left;
  line-height: 50px;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 30px;
}
.variant-select {
  padding-left: 10px;
}

.variant-select select {
  width: 340px;
  font-size: 14px;
  font-family: 'Chivo Mono', sans-serif;
}

.ProductHeader.antonsport {
  background-color: #fff;
}

.ProductHeader img {
  max-height: 80%;
  max-width: 90%;
  display: inline-block;
  display: none;
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  font-family: 'flv6laze1ec2un-lanp', sans-serif;
}

h1 {
  letter-spacing: -0.5px;
  font-size: 20px;
  display: block;
  width: 100%;
  font-weight: 600;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0 0 0 10px;
}

button.button.vipps {
  background-image: url('./assets/vipps_hurtigkasse_rect_250_NO@2x.png');
  background-repeat: no-repeat;
  background-color: #ff5b23;
  background-size: 80% auto;
  background-position: center center;
  border-radius: 5px;
  width: 100%;
  text-indent: -9999px;
  height: 50px;
  border: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 0px;
}

.button.vipps:hover {
  opacity: 0.85 !important;
  cursor: pointer;
}

button.vipps.success {
  text-indent: 0;
  opacity: 1;
  background: #5e8462;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.image-container img {
  /*max-width: 55% !Important;*/
  /*height: auto;*/
  height: 310px;
}

@media screen and (max-height: 570px) {
  .image-container img {
    height: 290px;
  }
}

@media screen and (max-height: 520px) {
  .image-container img {
    height: 230px;
  }
}

.image-container {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  width: 350px;
  min-height: 354px;
  padding-top: 10px;
  border-radius: 5px;
  max-width: 370px;
  overflow: hidden;
  /* border: 1px solid #eceff1; */
  /*border-radius: 15px;*/
  margin-left: 0;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media screen and (max-height: 570px) {
  .image-container {
    min-height: 330px;
  }
}
@media screen and (max-height: 520px) {
  .image-container {
    min-height: 250px;
  }
}

.product-price {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  line-height: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  padding-left: 10px;
}

.current-price {
  color: black;
  display: inline-block;
}

.old-price {
  text-decoration: line-through;
  display: inline-block;
  font-weight: 300;
  font-size: 16px;
  margin-left: 15px;
  margin-right: 20px;
  min-width: 1px;
}

.row {
  display: flex;
  padding-left: 0px;
  padding-right: 5px;
}

.description-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #dcdcdc;
  border-radius: 10px;
  margin-top: 18px;
  position: relative;
  margin-bottom: 20px;
}

.description-prod {
  width: 100%;
  height: 104px;
  margin-left: auto;
  margin-right: auto;
  border: 0px solid #dcdcdc;
  border-radius: 10px;
  margin-top: 0px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 18px;
  color: #3a3a3a;
}

.description-prod.full {
  background: #eceff1;
  padding: 20px;
  float: none;
  color: black;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
  font-size: 16px;
  height: auto;
  line-height: 21px;
  margin-bottom: 20px;
}

.description-prod {
}

.description-container p {
  font-size: 14px;
  /*font-family: "Roboto", sans-serif;*/
  font-weight: 300;
}

.description-container li {
  font-size: 14px;
  /*font-family: "Roboto", sans-serif;*/
  font-weight: 300;
}

.description-container strong,
.description-container b {
  font-weight: 400;
}

.accordion__item {
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
  cursor: pointer;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 9px;
  width: 9px;
  margin-right: 12px;
  border-bottom: 2px solid #404040;
  border-right: 2px solid #404040;
  transform: rotate(-45deg);
  position: absolute;
  right: 20px;
  top: 20px;
}

.vipps:disabled {
  opacity: 0.8 !important;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
  top: 18px;
}

.accordion__header {
  color: #404040;
  /*font-family: Roboto, sans-serif;*/
  font-size: 14px;
}

.product-counter {
  width: 100%;
  display: block;
  /*font-family: Roboto, sans-serif;*/
  font-size: 16px;
  color: #040404;
  margin-top: 18px;
  margin-bottom: 18px;
}

.product-counter .minus,
.product-counter .plus {
  width: 30px;
  height: 30px;
  border: 0;
  text-indent: -999px;
  cursor: pointer;
  outline: none;
  line-height: 16px;
  display: inline-block;
  border-radius: 5px;
  background-size: 60% 60%;
}

.product-counter .minus:hover,
.product-counter .plus:hover {
  background-color: #eceff166;
}

.current {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  width: 30px;
  text-align: center;
  color: #2c393f;
}

.minus {
  background: url('./assets/minus.svg') no-repeat;
  background-color: #eceff1;
  background-position: center center;
  margin-left: 20px;
}

.plus {
  background: url('./assets/plus.svg') no-repeat;
  background-color: #eceff1;
  background-position: center center;
}

.additional-information {
  font-size: 13px;
  width: 100%;
  /*font-family: Roboto, sans-serif;*/
  font-weight: 500;
  color: #404040;
}

.additional-information .row {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  vertical-align: middle;
}

/*.additional-information .first-col {*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    vertical-align: top;*/
/*    text-align: left;*/
/*    margin-bottom: 5px;*/

/*}*/

.additional-information .full-col {
  flex: 1 0 100%;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  text-align: left;
  margin-top: 10px;
  padding-left: 10px;
}

.additional-information .first-col,
.additional-information .second-col {
  text-align: left;
  vertical-align: top;
  float: right;
  color: #5e8462;
  font-size: 11px;
  height: 30px;
  margin-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 196px;
}

.additional-information .second-col {
  width: 150px;
  margin-right: 0;
}

/*.additional-information .second-col {*/
/*    text-align: right;*/
/*    vertical-align: top;*/
/*    color: #5E8462;*/
/*    float:right;*/
/*    font-size: 11px;*/
/*    height:30px;*/
/*    position: relative;*/
/*}*/

#retur {
  width: 20px;
  margin-right: 5px;
}

#card-logo {
  width: 16px;
  left: 0;
  position: absolute;
}

#priv-logo {
  width: 16px;
  left: 0;
  top: -3px;
  position: absolute;
}

#shipping {
  width: 20px;
  margin-right: 5px;
}

#vipps-logo {
  width: 60px;
  position: absolute;
  top: -6px;
}

#hhn-logo {
  width: 70px;
  position: absolute;
  margin-top: 0px;
  margin-left: 8px;
}

.summary-price {
  font-weight: 300;
  display: inline-block;
  margin-left: 10px;
}

.shipping-details {
  width: 100%;
  min-height: 70px;
  background-image: repeating-linear-gradient(134deg, #404040, #404040 4px, transparent 4px, transparent 8px, #404040 8px),
    repeating-linear-gradient(224deg, #404040, #404040 4px, transparent 4px, transparent 8px, #404040 8px),
    repeating-linear-gradient(-46deg, #404040, #404040 4px, transparent 4px, transparent 8px, #404040 8px),
    repeating-linear-gradient(44deg, #404040, #404040 4px, transparent 4px, transparent 8px, #404040 8px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-top: 18px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.shipping-row span {
  display: block;
  margin-left: 25px;
  color: #5e8462;
}

.shipping-row {
  font-size: 11px;
  font-weight: 500;
}

#delivery-icon {
  margin-right: 5px;
  display: inline-block;
}

#helthem-icon {
  margin-left: 3px;
  position: relative;
  top: 2px;
}

.success {
  background: rgb(83, 166, 83);
  background: linear-gradient(180deg, rgba(83, 166, 83, 1) 0%, rgba(64, 129, 64, 1) 100%);
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.1);
}

.cancel {
  color: #404040;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.success strong {
  font-size: 18px;
  display: inline-block;
  margin-bottom: 10px;
}

.success span {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  font-weight: 300;
}

.button-wrapper h1 {
  margin: 0;
  padding: 0;
}

.BrainhubCarousel__dots {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 5px !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  background: transparent;
}

.BrainhubCarousel__dot--selected:before {
  background: #ff5b23 !important;
}

.modal {
  font-size: 14px;
  color: #011627;
  background-color: #fff;
  box-shadow: 0 -5px 55px rgba(50, 50, 50, 0.9);
  border-bottom: none;
  position: fixed;
  bottom: -45px;
  left: 0;
  right: 0;
  height: 245px;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  text-align: justify;
  font-weight: 300;
}

.modal button:hover {
  background: #5e8462;
}

.modal button {
  border: 0;
  background: #7fb685;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  right: 20px;
  bottom: 15px;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#privacy img {
  position: relative;
  margin-top: 3px;
  left: 0;
  margin-right: 5px;
}

#privacy {
  padding-left: 0px;
  font-weight: 500;
  text-align: center;
}

#privacy:hover {
  text-decoration: none;
}

.ReactModal__Overlay__Customr {
  background-color: rgba(10, 10, 10, 0.5) !important;
}

.ReactModal__Content__Custom {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding-bottom: 60px !important;
  inset: 30px !important;
  bottom: 50% !important;
  top: auto !important;
}

.ReactModal__Content__Custom button {
  border: 0;
  background: #7fb685;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  right: 20px;
  bottom: 15px;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.left {
  width: 45%;
  float: left;
}

.right {
  width: 55%;
  float: left;
}

.desktop-only {
  display: block;
}
.mobile-only {
  display: none;
}

.BrainhubCarousel__dots {
  position: relative !important;
  max-width: 100% !important;
}

.info-price {
  font-size: 16px;
  color: #ff5b23;
  /*font-family: arial, sans-serif;*/
  position: absolute;
  margin-left: 20px;
  cursor: pointer;
  margin-top: 1px;
}
.bonusPrice {
  font-weight: normal !important;
}
.bonusPrice a {
  color: inherit;
}
.info-bonus {
  width: 16px;
  position: absolute;
  color: green;
  font-family: arial, sans-serif !important;
  margin-left: -20px;
  margin-top: 1px;
}

.checkbox-wrapper {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 10px;
  text-align: center;
  /*font-family: Roboto, sans-serif;*/
  font-size: 13px;
  color: #353535;
}

.ProductPage {
  max-width: 1024px;
  margin: auto;
}

#product-brand-name {
  opacity: 0.75;
  padding-left: 10px;

  margin-bottom: 10px;
}
#product-brand-name img {
  max-height: 25px;
  width: auto;
}
.description-prod {
  margin-bottom: 10px;
}
.description-prod > div {
  height: 92px;
  width: 100%;
  overflow: hidden;
}
.description-prod.full > div {
  height: 62px;
  text-overflow: ellipsis;

  width: 100%;
  overflow: hidden;
}
.description-prod.open {
  height: auto;
}
.description-prod > div:empty {
  height: 0 !important;
}
.description-prod.open > div {
  height: auto;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
}

.readMore {
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;

  //color:#FF5B23;
  margin-bottom: 5px;
}

.readMore:hover {
  text-decoration: none;
}

.button-wrapper {
  position: relative;
  width: 350px;
  border-radius: 4px;
  padding: 20px;
  margin-top: 15px;
  text-align: center;
}

@media screen and (max-height: 560px) {
  .button-wrapper {
    margin-top: 5px;
    padding: 5px 20px;
  }
}

#tline {
  position: absolute;
  margin-left: 85px;
  margin-top: 2px;
}
#where-buy {
  font-weight: bold;
}

.choose-size span {
  display: inline-block;
  padding: 10px;
  text-align: center;
  margin-right: 5px;
  border: 1px solid #cdcdcd;
  margin-bottom: 5px;
  font-size: 12px;
}

.choose-color span {
  display: inline-block;
  padding: 10px;
  text-align: center;
  margin-right: 5px;
  border: 1px solid #cdcdcd;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 12px;
}
.available-true {
  box-shadow: rgb(205 205 205) 0px 0px 0px 1px inset;
}
.available-true:hover {
  cursor: pointer;
  box-shadow: rgb(205 205 205) 0px 0px 0px 2px inset;
}
.available-true.selected {
  box-shadow: rgb(255 82 0) 0px 0px 0px 2px inset;
}
.available-false {
  background: #cdcdcd;
  opacity: 0.5;
  cursor: default;
}

.choose-color ul {
  padding-left: 0;
  margin-top: 5px;

  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  gap: 10px 14px;
  flex-wrap: wrap;
}

.choose-color li {
  display: block;
  padding: 12px;
}

.choose-color,
.choose-size {
  color: #404040;
  margin-bottom: 20px;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 20px;
}

.choose-size ul {
  padding-left: 0;
  margin-top: 5px;

  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  gap: 10px 14px;
  flex-wrap: wrap;
}

.choose-size li {
  display: block;
  padding: 15px;
}
